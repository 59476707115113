<template>
    <b-overlay v-if="$store.state.auth.user.is_legal" :show="show" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">

        <b-row>
            <b-col sm="12">
                <validation-observer ref="legalCompaniesTab">
                    <b-card :title="$t('legal_companies')">
                        <ul>
                            <li class="text-danger">{{ $t('legal_documents.item1') }}</li>
                            <li class="text-danger">{{ $t('legal_documents.item2') }}</li>
                            <li class="text-danger">{{ $t('legal_documents.item3') }}</li>
                            <li class="text-danger">{{ $t('legal_documents.item4') }}</li>
                            <li class="text-danger">{{ $t('legal_documents.item5') }}</li>
                        </ul>
                        <!-- form -->
                        <b-form class="mt-2">
                            <b-row>
                                <!-- Id -->
                                <b-col sm="12" lg="4" md="6">
                                    <b-form-group :label="$t('company_name')" label-for="company_name">
                                        <validation-provider #default="{ errors }" :name="$t('company_name')"
                                            vid="company_name" rules="required">
                                            <b-form-input v-model="form.company_name" :placeholder="$t('company_name')"
                                                name="company_name" autocomplete="off"
                                                @keypress="onlyAlphaAndPersian($event)" />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <!--/ Id -->
                                <!-- Id -->
                                <b-col sm="12" lg="4" md="6">
                                    <b-form-group :label="$t('Company_national_ID')" label-for="company_national_id">
                                        <validation-provider #default="{ errors }" :name="$t('Company_national_ID')"
                                            vid="company_national_id" rules="required">
                                            <b-form-input v-model="form.company_national_id"
                                                :placeholder="$t('Company_national_ID')" name="company_national_id"
                                                autocomplete="off" @keypress="onlyNumbers($event)" />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <!--/ Id -->
                                <!-- Id -->
                                <b-col sm="12" lg="4" md="6">
                                    <b-form-group :label="$t('city_registration')" label-for="city_of_registration">
                                        <validation-provider #default="{ errors }" :name="$t('city_registration')"
                                            vid="city_of_registration" rules="required">
                                            <b-form-input v-model="form.registration_city"
                                                :placeholder="$t('city_registration')" name="city_of_registration"
                                                autocomplete="off" @keypress="onlyAlphaAndPersian($event)" />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <!--/ Id -->
                                <!-- birth date -->
                                <b-col sm="12" lg="4" md="6">
                                    <b-form-group label-for="date_of_company_registration"
                                        :label="$t('date_of_company_registration')">
                                        <validation-provider #default="{ errors }"
                                            :name="$t('date_of_company_registration')" vid="date_of_company_registration"
                                            rules="required">
                                            <date-picker id="date_of_company_registration" v-model="form.registration_date"
                                                :color="primaryColor" :locale="citizenship === 'iran' ? 'fa' : 'en'"
                                                name="date_of_company_registration"
                                                :placeholder="$t('date_of_company_registration')" autocomplete="off" />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <!--/ birth date -->
                                <b-col sm="12" lg="4" md="6">
                                    <b-form-group label-for="company-type" :label="$t('company_type')">
                                        <validation-provider #default="{ errors }" :name="$t('company_type')"
                                            vid="company_type" rules="required">
                                            <v-select v-model="form.company_type_id" id="company-type"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name"
                                                :options="companyType" name="company_type"
                                                :reduce="companyType => companyType.id" autocomplete="off"
                                                :placeholder="$t('company_type')" />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="12" lg="4" md="6">
                                    <b-form-group :label="$t('legal_representative_name')"
                                        label-for="legal_representative_name">
                                        <validation-provider #default="{ errors }" :name="$t('legal_representative_name')"
                                            vid="legal_representative_name" rules="required">
                                            <b-form-input v-model="form.legal_representative_name"
                                                :placeholder="$t('legal_representative_name')"
                                                name="legal_representative_name" autocomplete="off"
                                                @keypress="onlyAlphaAndPersian($event)" />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-form>
                    </b-card>
                    <b-card>
                        <validation-provider #default="{ errors }" :name="$t('file')" vid="fileRecords" rules="required">
                            <!-- :accept="'image/*,.zip,.pdf,.doc,.docx,.ods'"-->
                            <VueFileAgent ref="vueFileAgent" :theme="'grid'" :multiple="true" :deletable="true" :meta="true"
                                :accept="'image/*,.pdf'" :maxSize="maxUploadSize" :maxFiles="20" name="fileRecords"
                                :editable="false" :helpText="'Choose images or zip files'" :errorText="{
                                    type: $t('InvalidFileTypeOnlyImagesAllowed'),
                                    size: $t('FilesShouldNotExceedInSize', { size: maxUploadSize }),
                                }" @beforedelete="onBeforeDelete($event)" v-model="form.file_proof"></VueFileAgent>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <div class="clearfix"></div>
                        <b-button @click="submit()" v-if="!pending" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary" class="mt-2 mr-1" :disabled="$store.state.profile.profileInfo.isActive == 0">
                            {{ $t('save_changes') }}
                        </b-button>
                        <b-button v-if="pending" variant="primary" class="mt-2 mr-1" disabled>
                            <b-spinner small type="grow" />
                            <span>{{ $t('loading') }}</span>
                            <span>...</span>
                        </b-button>
                        <div v-if="uploadSize > 0" class="text-center">
                            <h5 class="mt-2">
                                {{ $t('uploading') }}
                            </h5>
                            <b-progress :value="currentUpload" :max="uploadSize" show-progress animated dir="ltr"
                                height="20px" />
                        </div>
                    </b-card>
                </validation-observer>
            </b-col>
        </b-row>
        <b-card>
            <h3>{{ $t('legalCompaniesPage.legalCard') }}</h3>
            <p>{{ $t('legalCompaniesPage.legalCardDesscription') }}</p>
            <hr>
            <b-row>
                <b-col v-for="legalInfo in legalInfos" :key="legalInfo.id" class="mx-1 pt-1 col-xl-4" sm="12" md="12">

                    <div class="shadow p-1" style="border-radius: 8px;">
                        <b-card-text>
                            <div class="d-flex justify-content-between align-items-center">
                                <span>{{ $t('status') }} : :{{ $t(legalInfo.status) }}</span>
                                <feather-icon
                                    :icon="(legalInfo.status === 'pending' ? 'CircleIcon' : legalInfo.status === 'success' ? 'CheckIcon' : 'XCircleIcon')"
                                    size="30" class="mx-1"
                                    :stroke="(legalInfo.status === 'pending' ? 'orange' : legalInfo.status === 'success' ? 'green' : 'red')" />
                            </div>
                            <br>
                            <div class="d-flex justify-content-between flex-wrap">
                                <span>{{ $t('company_name') }}: </span>
                                <span>{{ legalInfo.company_name }}</span>
                            </div>
                            <div class="d-flex justify-content-between flex-wrap">
                                <span>{{ $t('Company_national_ID') }}: </span>
                                <span>{{ legalInfo.company_national_id }}</span>
                            </div>
                            <div class="d-flex justify-content-between flex-wrap">
                                <span> {{ $t('city_registration') }}: </span>
                                <span>{{ legalInfo.registration_city }}</span>
                            </div>
                            <div class="d-flex justify-content-between flex-wrap">
                                <span>{{ $t('date_of_company_registration') }}: </span>
                                <span>{{ legalInfo.registration_date }}</span>
                            </div>
                            <div class="d-flex justify-content-between flex-wrap">
                                <span>{{ $t('company_type') }}: </span>
                                <span>{{ legalInfo.company_type.name }}</span>
                            </div>
                            <div class="d-flex justify-content-between flex-wrap">
                                <span>{{ $t('legal_representative_name') }}: </span>
                                <span>{{ legalInfo.legal_representative_name }}</span>
                            </div>
                        </b-card-text>
                    </div>
                </b-col>
            </b-row>
        </b-card>
    </b-overlay>
</template>

<script>
import {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BSpinner,
    BFormFile,
    BOverlay,
    BProgress,
    BListGroup,
    BListGroupItem
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { mapGetters } from 'vuex'
import flatPickr from 'vue-flatpickr-component'
import { alphanumeric } from '@/mixins/alphanumeric'
import vSelect from 'vue-select'
import BCardCode from '@core/components/b-card-code'
import { BAlert, BCardText } from 'bootstrap-vue'
import legalApis from '@/services/apis/documents/legal'
import ProfileApis from '@/services/apis/profile'
import DatePicker from 'vue-persian-datetime-picker'

const profile = new ProfileApis()
const legal = new legalApis()

export default {
    components: {
        BForm,
        BRow,
        BCard,
        BFormFile,
        BButton,
        BCol,
        BFormInput,
        BFormGroup,
        flatPickr,
        BSpinner,
        vSelect,
        BCardCode,
        BAlert,
        BCardText,
        BOverlay,
        BProgress,
        BListGroup,
        BListGroupItem,
        DatePicker
    },
    directives: {
        Ripple,
    },
    mixins: [alphanumeric],
    data() {
        return {
            maxUploadSize: '5MB',
            show: false,
            uploadSize: 0,
            currentUpload: 0,
            required,
            pending: false,
            form: {
                company_name: '',
                company_national_id: '',
                registration_city: '',
                registration_date: '',
                company_type_id: '',
                legal_representative_name: '',
                file_proof: [],

            },
            companyType: [],
            legalInfos: []
        }
    },

    watch: {
        lang() {
            this.getCompanyType()
        }
    },
    computed: {
        ...mapGetters(['getCitizenship',]),

        lang() {
            return this.$i18n.locale
        },
        primaryColor() {
            return process.env.VUE_APP_PRIMARY_COLOR
        },
        citizenship() {
            return this.$store.state.auth?.user?.citizenship?.name.toLowerCase()
        },
    },
    async created() {
        await this.getData()
        await this.getCompanyType()
        this.show = false
    },
    methods: {
        async getData() {
            this.show = true
            await legal.getDocumentLegalInfo().then(res => {
                this.legalInfos = res.data.results
            })
        },
        async getCompanyType() {
            await legal.getCompanyTypes().then(res => {
                this.companyType = res.data.results
            })
        },
        async submit() {
            const success = await this.$refs.legalCompaniesTab.validate()
            if (success) {
                const imageSize = (process.env.VUE_APP_RESIZE_IMAGE_SIZE ? process.env.VUE_APP_RESIZE_IMAGE_SIZE : 3072) * 1024
                let file_proof_f = []
                for (const item of this.form.file_proof) {
                    file_proof_f.push(item.file)
                }
                let data = { ...this.form, file_proof: file_proof_f }

                delete data.file_proof_f
                this.pending = true
                await legal.saveDocumentLegal(data, e => {
                    this.uploadSize = e.total
                    this.currentUpload = e.loaded
                }).then(res => {
                    this.$swal({
                        title: res.data.message,
                        icon: 'success',
                        timer: 2500,
                        showConfirmButton: false,
                    })
                    this.legalInfos = [res.data.results.legalCompanies[0]]
                    this.pending = false
                    profile.get()
                })
                    .finally(() => {
                        this.pending = false
                        this.uploadSize = 0
                        this.currentUpload = 0
                    })
            }
        },
        baseUrl() {
            return process.env.VUE_APP_BASE_IMAGE_URL
        },
        onBeforeDelete: function (fileRecord) {
            var i = this.form.file_proof.indexOf(fileRecord);
            if (i !== -1) {
                // queued file, not yet uploaded. Just remove from the arrays
                this.form.file_proof.splice(i, 1);
            }
        },


    },

}
</script>
